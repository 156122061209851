@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  margin: 20px;
  padding: 0;
  background-color: #101119;
  overflow-y: scroll;
}

.App {
  text-align: center;
}

.App-header {
  padding: 20px;
  padding-bottom: 100px;
}

.button-container {
  display: flex;
  justify-content: space-around;
}

.button-container button {
  border: 3px solid white;
  border-radius: 8px; /* Adjust the value for desired roundness */
  background-color: #101119;
  color: white;
  padding: 20px 10px;
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s; /* Add transition for background-color and border-color */
  height: 65px;
  width: 15vw;
  font-size: large;
}

.button-container button:hover {
  background-color: #180e3d;
  color: white;
}

.generate-button {
  position: absolute;
  left: 50vw;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 30px;
}

.selected {
  background-color: #4000f8 !important;
  border-color: #4000f8 !important;
}

.container {
  display: flex;
}

.input-container {
  margin-top: 50px;
  text-align: left;
  margin-left: 3%;
  flex: 1;
  padding-right: 20px;
  width: 50%;
  z-index: 0;
}

h1 {
  margin-top: 30px;
  font-size: 15px;
  font-weight: 400;
  color: white;
}

.small-info {
  border: 0px;
  border-radius: 8px;
  padding: 16px 30px;
  font-size: 13px;
  position: relative;
  top: 0px;
  background-color: #dadada;
}

input[type="text"] {
  width: 100%;
  padding-left: 15px;
}

input[type="text"]::placeholder {
  color: #a7a7a7;
  position: absolute;
  top: 15px;
  left: 15px;
  pointer-events: none; /* Ensure the placeholder text doesn't interfere with input */
}

.large-info {
  border: 0px;
  border-radius: 8px;
  padding: 56px 30px;
  font-size: 15px;
  height: 100px;
  position: relative;
  top: 0px;
  min-height: 100px;
  font-family: sans-serif;
  background-color: #dadada;
}

.large-info-action {
  border: 0px;
  border-radius: 8px;
  padding: 56px 30px;
  font-size: 15px;
  height: 300px;
  position: relative;
  top: 0px;
  min-height: 300px;
  font-family: sans-serif;
  margin-bottom: 20px;
  background-color: #dadada;
}

textarea {
  min-height: 70px;
  resize: vertical;
}

textarea[type="text"] {
  width: 100%;
  padding-left: 15px;
  padding-top: 15px;
}

textarea[type="text"]::placeholder {
  color: #a7a7a7;
  position: absolute;
  top: 15px;
  left: 15px;
  pointer-events: none; /* Ensure the placeholder text doesn't interfere with input */
}

.empty-container {
  margin-top: 50px;
  position: relative;
  width: 50%;
  padding-left: 20px;
  margin-right: 3%;
  z-index: 0;
}

.empty-text-box {
  position: absolute;
  top: 0;
  width: 100%; /* Adjust the width as needed */
  height: 720px;
  background-color: #101119; /* Light gray background */
  border: 3px solid white;
  border-radius: 8px;
  margin-top: 57px;
}

.empty-label-text {
  margin-top: 30px;
  font-size: 15px;
  font-weight: 400;
  color: white;
  text-align: left;
}

.empty-text-box-email {
  position: absolute;
  top: 0;
  width: 100%; /* Adjust the width as needed */
  height: 355px;
  background-color: #101119; /* Light gray background */
  border: 3px solid white;
  border-radius: 8px;
  margin-top: 57px;
}

.empty-text-box-health {
  position: absolute;
  top: 0;
  width: 100%; /* Adjust the width as needed */
  height: 263px;
  background-color: #101119; /* Light gray background */
  border: 3px solid white;
  border-radius: 8px;
  margin-top: 57px;
}

.white-button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.white-button-container button {
  border-radius: 15px; /* Adjust the value for desired roundness */
  background-color: white;
  color: black;
  padding: 20px 80px;
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s; /* Add transition for background-color and color */
  z-index: 0;
}

.white-button-container button:hover {
  color: #4000f8;
}

.generated-text {
  width: 100%;
  padding: 15px;
  text-align: left;
  margin-top: 0;
  margin-left: 0;
  white-space: pre-wrap;
  overflow: auto; /* Add this line to enable scrolling */
  max-height: 338px; /* Add this line to limit the height and enable scrolling */
}

.generated-text .subject {
  font-weight: bold; /* Ensure bold font weight */
  margin: 0;
}

.generated-text-customer {
  width: 100%;
  padding: 15px;
  text-align: left;
  margin-top: 0;
  margin-left: 0;
  white-space: pre-wrap;
  overflow: auto; /* Add this line to enable scrolling */
  max-height: 695px; /* Add this line to limit the height and enable scrolling */
}

.generated-text-customer .subject {
  font-weight: bold; /* Ensure bold font weight */
  margin: 0;
}

.generated-text-action {
  width: 100%;
  padding: 15px;
  text-align: left;
  margin-top: 0;
  margin-left: 0;
  white-space: pre-wrap;
  overflow: auto; /* Add this line to enable scrolling */
}

.generated-text-action .subject {
  margin: 0;
}

.sign-up-button {
  /* Add your button styling here */
  background-color: #ccc; /* Example background color */
  color: black; /* Example text color */
  border: none; /* Example border styling */
  padding: 8px 16px; /* Example padding */
  border-radius: 4px; /* Example border radius */
  transition: background-color 0.2s, color 0.2s;
}

.sign-up-button:hover {
  /* Add your button styling here */
  background-color: #4000f8; /* Example background color */
  color: white; /* Example text color */
  border: none; /* Example border styling */
  padding: 8px 16px; /* Example padding */
  border-radius: 4px; /* Example border radius */
}

/* health tab */

.box-health {
  display: flex;
  padding-top: 50px;
}

.container-health {
  width: 80vw;
  height: 400px;
  border: none;
  margin: 10px;
  position: relative;
  box-sizing: border-box;
  border-radius: 15px;
}

.container-health-2 {
  width: 80vw;
  height: 400px;
  border: 3px solid white;
  border-radius: 10px; /* Adjust the value for desired roundness */
  margin: 10px;
  position: relative;
  box-sizing: border-box;
  border-radius: 15px;
}

.gray-text-2 {
  position: relative;
  top: -60px;
  font-size: 15px;
  font-weight: 400;
  color: white;
  pointer-events: none;
  text-align: left;
}

.inner-border {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  border: 2px dotted grey;
  border-radius: 15px;
}

.specific-info-health {
  border: 3px solid grey; /* Border style for the textarea */
  border-radius: 8px;
  padding: 26px 30px;
  font-size: 15px;
  width: 94%;
  height: 400px;
  position: relative;
  top: -60px;
  min-height: 400px;
  font-family: sans-serif;
}

.specific-info-health-generate {
  border: 3px solid #ccc;
  border-radius: 8px;
  padding: 56px 30px;
  font-size: 20px;
  width: 98%;
  height: 250px;
  position: relative;
  top: 0px;
}

.upload-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centered-content {
  text-align: LEFT;
}
.company-info-task {
  border: 3px solid #ccc;
  border-radius: 8px;
  padding: 16px 30px;
  font-size: 20px;
  width: 94%;
  position: relative;
  top: 0px;
  margin-bottom: 30px;
}

.upload-text {
  font-size: 18px;
  padding: 1rem;
}

.button-upload-health {
  padding: 8px;
  border-radius: 5px;
  border: 2px solid grey;
  color: black;
  background-color: white;
}

.dropdown-menu {
  border: 3px solid #ccc;
  border-radius: 8px;
  padding: 16px 30px;
  font-size: 20px;
  width: 20%;
  position: relative;
  top: 50px;
}

.plus {
  font-size: 20px;
}

.error-message {
  color: red;
}

.action-item-button {
  position: relative;
  border: 2px solid white;
  border-radius: 30px;
  width: 220px;
  height: 60px;
  font-size: 16px;
  background-color: black;
  color: white;
  float: left;
  margin-left: 45px;
  margin-top: 15px;
  transition: background-color 0.2s; /* Add a smooth transition effect */
}

.action-item-button:hover {
  background-color: #4000f8; /* Change the background color on hover */
}

.white-button-container button {
  border: 3px solid black;
  border-radius: 15px; /* Adjust the value for desired roundness */
  background-color: white;
  color: black;
  padding: 20px 100px;
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;

  transition: background-color 0.2s, color 0.2s; /* Add transition for background-color and color */
}

.myDropdown {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  position: relative;
}

.myDropdown:hover,
.myDropdown:focus {
  background-color: black;
  top: 100%;
}

.myDropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  width: 400px;

  position: absolute;
  min-width: 160px;
  overflow: auto;
  right: 40px;
  top: 515px;
  z-index: 2;
}

.dropdown-content button {
  border: 3px solid white;
  border-radius: 10px; /* Adjust the value for desired roundness */
  background-color: #101119;
  color: white;
  padding: 20px 0px;
  width: 20vw;
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s; /* Add transition for background-color and border-color */
  margin-top: 10px;
}

.dropbtn {
  border: 3px solid white;
  border-radius: 10px; /* Adjust the value for desired roundness */
  background-color: #101119;
  color: white;
  padding: 20px 110px !important;
  width: 20vw;
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s; /* Add transition for background-color and border-color */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropbtn-selected {
  border: 3px solid white;
  border-radius: 10px; /* Adjust the value for desired roundness */
  background-color: #101119;
  background-color: #4000f8 !important;
  border-color: #4000f8 !important;
  padding: 20px 110px !important;
  width: 20vw;
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s; /* Add transition for background-color and border-color */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.show {
  display: block;
}

.header-text {
  font-weight: 300;
  font-size: 75px;
  margin-bottom: 0;
}

.subtitle-text {
  font-size: 24px;
  font-weight: 200;
  margin-top: 10px;
  margin-bottom: 80px;
}

.coming-soon {
  position: fixed;
  z-index: 0;
  left: 0;
  top: 50%;
  width: 100%;
  height: 60%;
  overflow: auto;
  background-color: rgba(16, 17, 25, 0.7); /* Semi-transparent background */
  position: absolute;
}

.coming-soon-content {
  background-color: #2e2f30;
  border-radius: 10px; /* Adjust the value for desired roundness */
  font-size: 32px;
  color: white;
  margin-top: 10%;
  margin-left: 40%;
  padding: 20px;
  width: 20%;
}

.shade {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(16, 17, 25, 0.7);
  top: 0;
  z-index: 3;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-200px, -50%);
  width: 400px;
  padding: 40px;
  background: white;
  box-sizing: border-box;
  border: 2px solid white;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: 3;
}
.box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: black;
  text-align: center;
}
.box .inputbox {
  position: relative;
}
.box .inputbox input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: black;
  letter-spacing: 1px;
  margin-bottom: 30px;
  border: none;
  border: 1px solid grey;
  outline: none;
  background: transparent;
  border-radius: 10px;
  font-family: sans-serif;
}

.box .inputbox input:focus {
  border-color: #4000f8;
  /* border-top: 2px solid white; */

  /* Change border color when input is focused */
}

input[type="email-text"] {
  width: 100%;
  padding-left: 15px;
}

input[type="email-text"]::placeholder {
  color: #a7a7a7;
  position: absolute;
  top: 10px;
  left: 10px;
  pointer-events: none; /* Ensure the placeholder text doesn't interfere with input */
}

input[type="password-text"] {
  width: 100%;
  padding-left: 15px;
}

input[type="password-text"]::placeholder {
  color: #a7a7a7;
  position: absolute;
  top: 10px;
  left: 10px;
  pointer-events: none; /* Ensure the placeholder text doesn't interfere with input */
}

/* .box .inputbox label
  {
    position:absolute;
    top:0;
    left:10px;
    padding:10px 0;
    font-size:14px;
    color:grey;
    pointer-events:none;
    transition:.5s;
    

  }
   .box .inputbox input:focus~label{
    top:-18px;
    left:5px;
    color:#4000F8;
    font-size:10px;
    background-color: white;
  
  } */
/* .box .inputbox input:valid~label
  {
    top:-18px;
    left:0;
    color:#03a9f4;
    font-size:12px;
  }  */

.no-account {
  font-size: 12px;
  margin: 20px;
}
.no-account span {
  font-size: 12px;
  color: #4000f8;
  padding-left: 10px;
}
.box input[type="submit"] {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 43px;
  color: white;
  background: #4000f8;
  padding: 10px 20px;

  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.line {
  border-top: 1px solid grey;
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.or {
  padding: 0px 10px; /* Adjust the padding as needed */
  background-color: white;
  font-size: 13px;
  margin-top: -10px;
}

.eesa-logo {
  width: 55px;
  height: 50px;
}

.icon-span {
  display: flex;
  align-items: center;
  text-align: center;
}

.g-logo {
  width: 55px;
  height: 50px;
  border: 1px solid grey;
  border-radius: 5px;
  margin: auto;
  margin-top: 10px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #101119;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding-bottom: 40px;
}

.logo {
  width: 326px;
  height: 104px;
}

.nav-links {
  display: flex;
  justify-content: space-evenly;
  gap: 2vw;
}

.nav-button {
  background: none;
  border: none;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  color: white;
  border-radius: 45px;
  font-weight: 500;
}

.try-for-free {
  background-color: #4000f8;
  color: white;
  padding: 20px 30px;
}

.home {
  background-color: #101119;
  color: #c5c4ff;
  padding: 15px 50px;
  border: 3px solid #c5c4ff;
}

.trash {
  font-size: smaller;
}

.playbook-type-dropdown {
  padding: 10px;
  background: #101119;
  color: #d9d9d9;
  border: 3px solid #d9d9d9;
  border-radius: 5px;
  width: 13vmax;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.playbook-type-dropdown-options {
  background: #101119;
  color: #d9d9d9;
  position: absolute;
  z-index: 1;
}

.playbook-type-dropdown-options ul {
  padding: 0;
  margin: 0;
}

.playbook-type-dropdown-options li {
  outline: 1px solid white;
  color: white;
  list-style-type: none;
  width: 13vmax;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: small;
  padding: 10px;
  cursor: pointer;
}

.playbook-type-dropdown-options li:hover {
  background-color: #3e00f82f;
}

.playbook-type-dropdown:hover {
  background-color: #3e00f82f;
}

.playbook-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-inline: 50px;
}

.playbook-result-box-title {
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: large;
  font-weight: 300;
  color: white;
}

.playbook-row {
  color: white;
  display: flex;
  flex-direction: row;
  font-size: small;
  font-weight: 300;
}

.playbook-result-spreadsheet {
  display: flex;
  flex-direction: column;
  margin-inline: 30px;
  margin-bottom: 30px;
}

.playbook-one {
  width: 20%;
  border-bottom: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
}
.playbook-two {
  width: 20%;
  border-bottom: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
}
.playbook-three {
  width: 25%;
  border-bottom: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
}
.playbook-four {
  width: 35%;
  border-bottom: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
}
.playbook-five {
  width: 25%;
  border-bottom: 1px solid #eaecf0;
}

.playbook-bottom {
  border-bottom: 1px solid transparent;
}

.playbook-row > div {
  padding: 10px;
  display: flex;
  text-align: left;
  border-top: none;
}

.playbook-result-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  margin-top: 30px;
}

.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.playbook-result-box {
  border: 3px solid #eaecf0;
  border-radius: 8px;
  width: 100%;
  height: auto;
  min-height: 20vh;
}

.playbook-magic-text {
  font-size: 15px;
  font-weight: 400;
  color: white;
}

.suggestions-modal {
  background-color: white;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  top: 25%;
  left: 30%;
  right: 30%;
  bottom: 25%;

  border-radius: 45px;
}

.suggestions-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #33333380;
}

.playbook-type-text {
  color: white;
  padding-bottom: 10px;
  font-size: 15px;
  font-weight: 400;
}

.circle-container {
  position: relative;
  /* margin-bottom:40px; */
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.info-text {
  position: absolute;
  top: 40px;
  left: 10px;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 11px;
  display: inline-block;
  white-space: nowrap;
}

.customer-persona-tooltip {
  width: 30px;
  margin-left: 50px;
  margin-bottom: -35px;
  height: 30px;
}

.button-and-content-container {
  margin-inline: 50px;
}

@media (max-width: 1200px) {
  .button-and-content-container {
    margin-inline: 0;
  }
}

/* *{outline:1px solid red;} */

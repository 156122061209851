.suggestions-box{
  margin-top: 70px;
    padding: 10px;
    gap: 10px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
  }

  .suggestions-text{
    color:white;

  }

  .suggestions-button{
    background-color: #4000F8;
    color:white;
    border: none;
    padding: 10px;
    border-radius: 45px;
    cursor: pointer;
  }

  .suggestions-modal-interior{
    width: 100%;
    height: 90%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }

  .suggestions-modal-textarea{
    resize:none;
    width: 85%;
    height: 70%;
    border-radius: 8px;
    border-color: #CBCBCB;
    padding: 15px;
  }

  .suggestions-modal-textarea::placeholder{
    top: 20px;
    left: 20px;
    color: #A7A7A7;
  }

  .suggestions-modal-header{

    font-weight: 500;
    font-size: 18pt;

  }